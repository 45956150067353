import React from "react";

const Notifications = () => {
    return (
        <div className="notifications white">
            <p>Notifications</p>
        </div>
    )
}

export default Notifications