import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import SignedInLinks from "./SignedInLinks"
import SignedOutLinks from "./SignedOutLinks"

const Navbar = (props) => {

    const { auth, profile } = props;
    // console.log(auth);
    const links = auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />
    return (
        <nav className="nav-wrapper blue-grey darken-4 z-depth-2">
            <div className="container">
                <Link to='/' className="brand-logo">Project Planner</Link>
                { links }
            </div>
        </nav>
    );
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}
export default connect(
    mapStateToProps
)(Navbar)